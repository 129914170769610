import React from "react";
import ReactPlayer from "react-player";

export const ProjectVideo = ({video}) => {
    console.log("video", video);
  return (
    <>
      {
        /*https://www.npmjs.com/package/react-player */
        video ? (
          <ReactPlayer
            url={video}
            playing
            controls={true}
            style={{
              position: "relative",
              float: "left",
              width: "640px",
              height: "360px",
            }}
          />
        ) : (
          <></>
        )
      }
    </>
  );
}
