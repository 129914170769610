import React, { useState, useEffect } from "react";
import { createBrowserHistory } from "history";
import { Switch, Router } from "react-router";
import { Route } from "react-router-dom";
import api from "../api/api";
import { Project } from "../components/Project";
import { Projects } from "../containers/Projects";
import "../local-modules/react-image-lightbox/src/style.css";

const history = createBrowserHistory();
/*
@see https://frontend-collective.github.io/react-image-lightbox/
*/

const App = () => {
  const [appState, setAppState] = useState({
    projects: [],
    errors: null,
    photoIndex: 0,
    isOpen: false,
    isMobile: false,
  });

  const setThumbnails = () => {
    const viewWidth = window.innerWidth;
    if (viewWidth < 1024) {
      setAppState((prev) => {
        return {
          ...prev,
          isMobile: true,
        };
      });
    } else {
      setAppState((prev) => {
        return {
          ...prev,
          isMobile: false,
        };
      });
    }
  };
  useEffect(() => {
    const setData = async () => {
      await api.get("/wp-json/sergio-chaves/proyectos").then((response) => {
        setAppState((prev) => {
          return {
            ...prev,
            projects: response.data.proyects,
          };
        });
      });
    };
    if (appState?.projects?.length === 0) {
      setData();
    }
    setThumbnails();
    window.addEventListener("resize", setThumbnails);
    return () => {
      window.removeEventListener("resize", setThumbnails);
    };
  }, [appState?.projects?.length]);

  return (
    <>
      <Router history={history}>
        <div id="router">
          <Switch>
            <Route
              exact
              path="/"
              render={() => {
                return (
                  <Projects
                    isMobile={appState.isMobile}
                    projects={appState.projects}
                  />
                );
              }}
            />
            <Route
              exact
              path="/servicios"
              render={() => {
                return (
                  <Projects
                    isMobile={appState.isMobile}
                    projects={appState.projects}
                  />
                );
              }}
            />
            <Route
              exact
              path="/servicios/:service"
              render={() => {
                return (
                  <Projects
                    isMobile={appState.isMobile}
                    projects={appState.projects}
                  />
                );
              }}
            />
            <Route
              exact
              path="/proyecto/"
              render={() => {
                return (
                  <Projects
                    isMobile={appState.isMobile}
                    projects={appState.projects}
                  />
                );
              }}
            />
            <Route
              exact
              path="/proyecto/:proyecto"
              render={(props) => {
                const slugProyect = props.location.pathname
                  .replace("/proyecto/", "")
                  .replace("/", "");
                return (
                  <Project
                    slugProyect={slugProyect}
                    appState={appState}
                    setAppState={setAppState}
                  />
                );
              }}
            />
          </Switch>
        </div>
      </Router>
    </>
  );
};
export default App;
