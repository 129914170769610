import React from "react";
import { NavLink } from "react-router-dom";
import uuid from "react-uuid";
import Lightbox from "../local-modules/react-image-lightbox/src/index";
import "../local-modules/react-image-lightbox/src/style.css";
import { ProjectVideo } from "./ProjectVideo";

export const Project = React.memo(({ slugProyect, appState, setAppState }) => {
  const { isOpen, photoIndex, projects } = appState;
  if (!projects.length) {
    return <></>;
  }
  const projectFilter = projects?.find(
    (project) => project.slug === slugProyect
  );
  let divContainerClass = "";
  if (!projectFilter) {
    return <></>;
  }
  const projectDescription =
    projectFilter.descripcion !== null
      ? projectFilter.descripcion
      : "<div></div>";

  return (
    <React.Fragment>
      <h1>{projectFilter?.pageTitle}</h1>
      <NavLink to="/" className="btn btn-black">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          style={{ width: "40px" }}
        >
          <g>
            <path
              style={{ fill: "#fff" }}
              d="M160,416L0,256.875L160,96v96h128c123.688,0,224,100.312,224,224c0-53-43-96-96-96H160V416z"
            />
          </g>
        </svg>
      </NavLink>
      {projectFilter?.slug === "restauracion-templo-san-rafael-de-heredia" ? (
        <div className="container col text-center">
          <button
            onClick={() =>
              (window.location.href =
                "https://sergiochavesarquitectos.co.cr/wp-content/uploads/2023/08/Informe-de-Labores-2016-2019.pdf")
            }
            className="btn btn-lg mrb50 m-2"
          >
            Abrir Informe de restauración 2016-2023
          </button>
        </div>
      ) : (
        <></>
      )}
      {projectFilter?.thumbnails?.images &&
      projectFilter?.thumbnails?.images?.length > 0 ? (
        projectFilter?.thumbnails?.images.map((image, index) => {
          if (image.size === 50) {
            divContainerClass = "col-lg-6 col-md-6 col-xs-12";
          } else if (image.size === 100) {
            divContainerClass = "col-lg-12 col-xs-12";
          } else if (image.size === 25) {
            divContainerClass = "col-lg-3 col-md-6 col-xs-12";
          }
          return (
            <div className={divContainerClass} key={uuid()}>
              <img
                key={image.idHtml}
                src={image.src}
                className="img-responsive lazy"
                alt={image.alt}
                title={projectFilter?.pageTitle}
                onClick={() => {
                  document.querySelector("main").scrollIntoView();
                  setAppState((prev) => {
                    return {
                      ...prev,
                      photoIndex: index,
                      isOpen: true,
                    };
                  });
                }}
              />
            </div>
          );
        })
      ) : (
        <></>
      )}
      {isOpen &&
      projectFilter?.thumbnails?.images &&
      projectFilter?.thumbnails?.images.length > 0 ? (
        <Lightbox
          mainSrc={
            projectFilter?.thumbnails?.images &&
            projectFilter?.thumbnails?.images[photoIndex] &&
            projectFilter?.thumbnails?.images[photoIndex].src
              ? projectFilter?.thumbnails?.images[photoIndex].src
              : projectFilter?.featuredImgUrl
          }
          nextSrc={
            projectFilter?.thumbnails?.images &&
            projectFilter?.thumbnails?.images[
              (photoIndex + 1) % projectFilter?.thumbnails?.images?.length
            ]?.src
          }
          prevSrc={
            projectFilter?.thumbnails?.images &&
            projectFilter?.thumbnails?.images[
              (photoIndex + projectFilter?.thumbnails?.images?.length - 1) %
                projectFilter?.thumbnails?.images?.length
            ]?.src
          }
          onCloseRequest={() =>
            setAppState((prev) => {
              return {
                ...prev,
                isOpen: false,
              };
            })
          }
          imageTitle={projectFilter?.pageTitle}
          imageCaption={projectDescription}
          onMovePrevRequest={() =>
            setAppState((prev) => {
              return {
                ...prev,
                photoIndex:
                  (photoIndex + projectFilter?.thumbnails?.images?.length - 1) %
                  projectFilter?.thumbnails?.images?.length,
              };
            })
          }
          onMoveNextRequest={() =>
            setAppState((prev) => {
              return {
                ...prev,
                photoIndex:
                  (photoIndex + 1) % projectFilter?.thumbnails?.images?.length,
              };
            })
          }
        />
      ) : (
        <></>
      )}

      {projectFilter?.videos?.url ? (
        <ProjectVideo video={projectFilter?.videos?.url} />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
});
