import React from "react";
import { NavLink } from "react-router-dom";
import LazyImage from "./LazyImage";

export const Thumbnail=(props) => {
  return (
    <React.Fragment>
      <li className="proyect-img with-caption image-link">
        <NavLink
          to={props.permanlink}
          onClick={() => {
            document.querySelector("main").scrollIntoView();
          }}
        >
          <div className="item_image ">
            <LazyImage
              src={props.featuredImgUrl}
              alt={props.pageTitle}
              title={props.pageTitle}
            />
          </div>
          <div className="caption-title">
            <h2
              style={{
                textAlign: "center",
                margin: "0 auto",
                fontSize: "12px",
                fontWeight: 300,
                paddingTop: "20px",
              }}
            >
              {props.pageTitle}
            </h2>
          </div>
        </NavLink>
      </li>
    </React.Fragment>
  );
}

