import React from "react";
import LazyLoad from "vanilla-lazyload"; 

if (!document.lazyLoadInstance) {
  document.lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy"
  });
}
export const LazyImage=(props)=>{
  React.useEffect(()=>{
    document.lazyLoadInstance.update();
  },[props?.src])

    const { alt, src, title } = props;
    return (
      <img
        alt={alt}
        className="lazy proyect-img"
        data-src={src}
        title={title}
        width="320px"
        height="210px"
      />
    );
}

export default LazyImage;