import React from "react";
import { Thumbnail } from "../components/Thumbnail";

export const Projects = React.memo(({ projects, isMobile }) => {
  const skeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  return (
    <>
      {projects && projects?.length ? (
        <ul className="proyects_container">
          {projects.map((project) => {
            return (
              <Thumbnail
                permanlink={project.permanlink}
                featuredImgUrl={
                  isMobile === true
                    ? project.featuredImgMobileUrl
                    : project.featuredImgUrl
                }
                pageTitle={project.pageTitle}
                id={project.id}
                key={project.id}
              ></Thumbnail>
            );
          })}
        </ul>
      ) : (
        <ul className="proyects_container">
          {skeletons.map((skel, index) => {
            return (
              <li
                className="proyect-img with-caption image-link skeleton"
                key={index}
                style={{
                  height: "210px",
                  width: "320px",
                }}
              ></li>
            );
          })}
        </ul>
      )}
    </>
  );
});
